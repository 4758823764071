* {
  font-family: Tahoma, Verdana, Arial, Helvetica, sans-serif;
}
html {
  position: relative;
}
html,
body {
  max-width: 100vw;
  min-height: 100vh;
  overflow-x: hidden;
  padding: 5px;
  margin: auto;
}

body {
  background-color: ivory;
  display: flex;
  flex-direction: column;
}

button {
  cursor: pointer;
}
.header {
  position: fixed;
  display: flex;
  flex-direction: row;
  align-items: center;

  /* font-weight: lighter; */
  height: 2.5rem;
  margin: 10 0 10 20;
  gap: 5;
}
.logo {
  background-image: url("icons/ctf.webp");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 4rem;
  width: 4rem;
  /* display: inline-block; */
}
.control-panel {
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 20;
  right: 20;
  gap: 2;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 10;
}

.form {
  display: inline-flex;
  flex-direction: row;
  background-color: lightgray;
  border-radius: 10px;
  padding: 5px;
  /* max-width: fit-content; */
}
.form-btns {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
}

.measure-entry {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: small;
}
.equals-bpm {
  display: flex;
  gap: 10;
}

.start-stop {
  width: 8rem;
  height: 2rem;
  margin: 2 0 2 0;
  font-weight: bold;
  color: black;
  /* border-color: #a8bbb0; */
  /* border-width: 3; */
}

.play {
  padding: 5;
  display: inline-flex;
  align-items: center;
  justify-content: space-evenly;
  border: 2px solid gray;
  border-radius: 10px;
  background-color: ivory;
}
#countoff-div,
#loop-div {
  font-size: smaller;
}

.popup {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  overflow-y: auto;
}

.options {
  display: grid;
  margin-bottom: 10px;
}
/* Show the popup when the button is clicked */
#load-saved-div:focus + .popup {
  display: block;
}

#manage-storage-div:focus + .popup {
  display: block;
}
fieldset,
button {
  border-radius: 5px;
}
.meter {
  display: block;
  justify-items: center;
  justify-content: center;
}
.numerator,
.denominator {
  display: block;
  width: 4rem;
  align-content: center;
}
.change-block {
  display: block;
  justify-content: center;
  padding-top: 5px;
}
.change-tempo-end {
  padding-top: 2px;
}

.hidden {
  display: none;
  opacity: 0;
}

.beat {
  display: block;
}

.entry {
  display: block;
  max-width: 50px;
}

.beat-icon {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 1rem;
  width: 1rem;
  display: inline-block;
}
.beat-equals {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 1rem;
  width: 1rem;
  display: inline-block;
}
.half {
  background-image: url("icons/half.webp");
}
.dotted-qtr {
  background-image: url("icons/dotted-quarter.webp");
}
.quarter {
  background-image: url("icons/quarter.webp");
}
.dotted-eighth {
  background-image: url("icons/dotted-eighth.webp");
}
.eighth {
  background-image: url("icons/eighth.webp");
}
.sixteenth {
  background-image: url("icons/sixteenth.webp");
}
.bar-elements {
  display: flex;
  justify-content: safe;
}
.notes {
  position: absolute;
  display: flex;
  /* justify-content: safe; */
  top: 0;
  left: 30;
}
.note {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 2rem;
  width: 2em;
  /* transform: rotate(180deg); */
  opacity: 60%;
}
.track-title {
  text-align: center;
  font-size: larger;
  margin-top: 200;
}

.measure-display {
  margin: 15 15 200 15;
  position: relative;
  /* top: 215; */
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: baseline;
  /* padding: 0 5 0 5; */
}
.measure {
  margin: 0 -10 0 -10;
  /* flex-grow: 1; */
  flex-shrink: 1;
  gap: 0;
  flex-basis: 150;
  max-width: fit-content;
}

.tempo {
  position: relative;
  display: inline-flex;
  left: 30;
}
.bar {
  position: relative;
}

.time-signature {
  font-family: "";
  position: absolute;
  left: 10;
  top: 0;
  display: flex;
  flex-direction: column;
  align-self: center;
  text-align: center;
  font-size: 210%;
}
.display-numerator,
.display-denominator {
  margin: -5 0 -5 0;
}
.tempo-change {
  margin-left: 20;
}

.staff svg {
  width: 100%; /* Fill the width of its container */
  height: 50px; /* Example height - adjust as needed */
}

.staff line {
  /* Assuming you have <line> elements within the SVG */
  stroke: rgba(0, 0, 0, 0.7);
  stroke-width: 1.2; /* Line thickness - adjust as desired */
}

.end-line {
  opacity: 0;
}
.measure:last-child {
  .end-line {
    stroke-width: 4;
    opacity: 100;
  }
}

.submit,
.undo,
.undo-ask {
  margin: 5px;
  padding: 5px;
  justify-self: center;
}

.bottom-controls {
  position: absolute;
  bottom: 100;
  /* z-index: 10; */
  display: inline-flex;
  align-self: center;
  border: 2px solid lightgray;
  border-radius: 10px;
  width: max-content;
}
/* .bottom-controls.sticky {
  position: fixed;
  bottom: 5;
} */

.manage-btns {
  display: grid;
  gap: 4px;
  max-width: 12rem;
  padding: 5;
}

.manage {
  height: 2rem;
}

footer {
  position: absolute;
  bottom: 0;
  align-self: center;
  overflow: hidden;
  background-color: #a8bbb0;
  margin-top: 5;
  padding-bottom: 5;
  width: 100vw;
  color: ivory;
}

.footer-row {
  font-size: small;
  display: flex;
  justify-content: space-evenly;
  padding: 10 50 0 50;
  align-items: center;
}

#coffee {
  scale: 70%;
}

@media (max-width: 600) {
  .header {
    font-size: large;
    top: 0;
  }
  .control-panel {
    position: fixed;
    top: 70;
    right: auto;
    align-self: center;
  }
  .measure-entry {
    font-size: 10px;
  }
  .track-title {
    margin-top: 250;
  }
  .measure-display {
    /* justify-content: center; */
    margin: 0 10 200 10;
    font-size: 10px;
  }
  .beat-icon {
    height: 0.8rem;
    background-position: right;
  }
  .tempo {
    font-size: xx-small;
    left: 20;
    top: 10;
    gap: 2;
  }
  .measure {
    flex-basis: 90;
  }
  .time-signature {
    font-size: 120%;
    top: 15;
    left: 15;
    gap: 3;
  }
  .bottom-controls {
    bottom: 110;
  }
  footer {
    font-size: x-small;
  }
  .footer-row {
    display: block;
    padding: 10 0 0 0;
    text-align: center;
  }
}
/* @media (min-height: 120vh) {
  .bottom-controls {
    position: fixed;
    bottom: 5;
  }
} */
