* {
  font-family: Tahoma, Verdana, Arial, Helvetica, sans-serif;
}

html {
  position: relative;
}

html, body {
  max-width: 100vw;
  min-height: 100vh;
  margin: auto;
  padding: 5px;
  overflow-x: hidden;
}

body {
  background-color: ivory;
  flex-direction: column;
  display: flex;
}

button {
  cursor: pointer;
}

.header {
  flex-direction: row;
  align-items: center;
  gap: 5px;
  height: 2.5rem;
  margin: 10px 0 10px 20px;
  display: flex;
  position: fixed;
}

.logo {
  background-image: url("ctf.2a4b62ab.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 4rem;
  height: 4rem;
}

.control-panel {
  z-index: 10;
  flex-direction: column;
  gap: 2px;
  display: flex;
  position: fixed;
  top: 20px;
  right: 20px;
  box-shadow: 0 2px 4px #0000001a;
}

.form {
  background-color: #d3d3d3;
  border-radius: 10px;
  flex-direction: row;
  padding: 5px;
  display: inline-flex;
}

.form-btns {
  justify-content: space-between;
  width: 100%;
  display: inline-flex;
}

.measure-entry {
  flex-direction: row;
  justify-content: space-between;
  font-size: small;
  display: flex;
}

.equals-bpm {
  gap: 10px;
  display: flex;
}

.start-stop {
  color: #000;
  width: 8rem;
  height: 2rem;
  margin: 2px 0;
  font-weight: bold;
}

.play {
  background-color: ivory;
  border: 2px solid gray;
  border-radius: 10px;
  justify-content: space-evenly;
  align-items: center;
  padding: 5px;
  display: inline-flex;
}

#countoff-div, #loop-div {
  font-size: smaller;
}

.popup {
  z-index: 1000;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px;
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  overflow-y: auto;
  transform: translate(-50%, -50%);
  box-shadow: 0 2px 4px #0000001a;
}

.options {
  margin-bottom: 10px;
  display: grid;
}

#load-saved-div:focus + .popup, #manage-storage-div:focus + .popup {
  display: block;
}

fieldset, button {
  border-radius: 5px;
}

.meter {
  justify-content: center;
  justify-items: center;
  display: block;
}

.numerator, .denominator {
  align-content: center;
  width: 4rem;
  display: block;
}

.change-block {
  justify-content: center;
  padding-top: 5px;
  display: block;
}

.change-tempo-end {
  padding-top: 2px;
}

.hidden {
  opacity: 0;
  display: none;
}

.beat {
  display: block;
}

.entry {
  max-width: 50px;
  display: block;
}

.beat-icon, .beat-equals {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 1rem;
  height: 1rem;
  display: inline-block;
}

.half {
  background-image: url("half.f70dd91f.webp");
}

.dotted-qtr {
  background-image: url("dotted-quarter.70bc8af0.webp");
}

.quarter {
  background-image: url("quarter.b724813d.webp");
}

.dotted-eighth {
  background-image: url("dotted-eighth.3024f559.webp");
}

.eighth {
  background-image: url("eighth.1b14f048.webp");
}

.sixteenth {
  background-image: url("sixteenth.68f64e82.webp");
}

.bar-elements {
  justify-content: safe;
  display: flex;
}

.notes {
  display: flex;
  position: absolute;
  top: 0;
  left: 30px;
}

.note {
  opacity: .6;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 2em;
  height: 2rem;
}

.track-title {
  text-align: center;
  margin-top: 200px;
  font-size: larger;
}

.measure-display {
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: baseline;
  margin: 15px 15px 200px;
  display: flex;
  position: relative;
}

.measure {
  flex-shrink: 1;
  flex-basis: 150px;
  gap: 0;
  max-width: -moz-fit-content;
  max-width: fit-content;
  margin: 0 -10px;
}

.tempo {
  display: inline-flex;
  position: relative;
  left: 30px;
}

.bar {
  position: relative;
}

.time-signature {
  text-align: center;
  flex-direction: column;
  align-self: center;
  font-family: "";
  font-size: 210%;
  display: flex;
  position: absolute;
  top: 0;
  left: 10px;
}

.display-numerator, .display-denominator {
  margin: -5px 0;
}

.tempo-change {
  margin-left: 20px;
}

.staff svg {
  width: 100%;
  height: 50px;
}

.staff line {
  stroke: #000000b3;
  stroke-width: 1.2px;
}

.end-line {
  opacity: 0;
}

.measure:last-child .end-line {
  stroke-width: 4px;
  opacity: 100;
}

.submit, .undo, .undo-ask {
  justify-self: center;
  margin: 5px;
  padding: 5px;
}

.bottom-controls {
  border: 2px solid #d3d3d3;
  border-radius: 10px;
  align-self: center;
  width: max-content;
  display: inline-flex;
  position: absolute;
  bottom: 100px;
}

.manage-btns {
  gap: 4px;
  max-width: 12rem;
  padding: 5px;
  display: grid;
}

.manage {
  height: 2rem;
}

footer {
  color: ivory;
  background-color: #a8bbb0;
  align-self: center;
  width: 100vw;
  margin-top: 5px;
  padding-bottom: 5px;
  position: absolute;
  bottom: 0;
  overflow: hidden;
}

.footer-row {
  justify-content: space-evenly;
  align-items: center;
  padding: 10px 50px 0;
  font-size: small;
  display: flex;
}

#coffee {
  scale: 70%;
}

@media (width <= 600px) {
  .header {
    font-size: large;
    top: 0;
  }

  .control-panel {
    align-self: center;
    position: fixed;
    top: 70px;
    right: auto;
  }

  .measure-entry {
    font-size: 10px;
  }

  .track-title {
    margin-top: 250px;
  }

  .measure-display {
    margin: 0 10px 200px;
    font-size: 10px;
  }

  .beat-icon {
    background-position: 100%;
    height: .8rem;
  }

  .tempo {
    gap: 2px;
    font-size: xx-small;
    top: 10px;
    left: 20px;
  }

  .measure {
    flex-basis: 90px;
  }

  .time-signature {
    gap: 3px;
    font-size: 120%;
    top: 15px;
    left: 15px;
  }

  .bottom-controls {
    bottom: 110px;
  }

  footer {
    font-size: x-small;
  }

  .footer-row {
    text-align: center;
    padding: 10px 0 0;
    display: block;
  }
}

/*# sourceMappingURL=index.1b7041e7.css.map */
